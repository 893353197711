.section-pages-button-area {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-pages-button-area .w-action-block.section-pages-link-module {
    padding: 0;
    width: 300px;
}

.section-pages-button-area .w-action-block.section-pages-link-module .w-button .k-button {
    width: 100%;
}
